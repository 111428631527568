import { useState } from "react";
import { useParams } from "react-router-dom";
import MobileWarning from "../components/mobileWarning";
import Checkout from "../components/checkout";

function Home() {
    const { orderId } = useParams();
    const [isMobile, setIsMobile] = useState(false);

    return (
        <>
            <MobileWarning isMobile={isMobile} setIsMobile={setIsMobile} />
            {!isMobile && <Checkout orderId={orderId} />}
        </>
    );
}

export default Home;
