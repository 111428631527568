import { useParams, useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import logo from "../assest/logo.png";
import contentData from "../services/content";

function NewOrder() {
    const navigate = useNavigate();
    const { orderId } = useParams();
    const { title, subtitle, content, footer, link, proceedToTest, proceedLater } = contentData.newOrder;

    const resendEmail = () => {
        fetch(`${process.env.REACT_APP_KESHEV_B2C_API_URL}/keshev-b2c-api/orders/${orderId}/resendEmail`);
    };

    return (
        <div className="d-flex flex-column align-items-start">
            <div className="container">
                <img src={logo} style={{ width: "210px", margin: "35px 0 70px" }} alt="" />
                <h2 className="fw-bold">{title}</h2>
                <p className="mt-4">
                    {subtitle} <span className="fw-bold">{orderId.slice(-6)}</span>
                    <br />
                    {content}
                </p>
                <div className="mt-4 mb-3">
                    <Button
                        variant="outline-primary"
                        type="submit"
                        className="btn-outline-special ms-3"
                        onClick={() => {
                            window.location.href = process.env.REACT_APP_WIX_URL;
                        }}
                    >
                        {proceedLater}
                    </Button>
                    <Button
                        variant="primary"
                        type="submit"
                        className="btn-special"
                        onClick={() => navigate(`/orders/${orderId}`)}
                    >
                        {proceedToTest}
                    </Button>
                </div>
                <p>
                    <span className="fw-bold">{footer.highlighted}</span>
                    <span>{footer.text} </span>
                    <a href="#resend" onClick={resendEmail}>
                        {footer.cta}
                    </a>
                    .
                </p>
                <p>{footer.text2}</p>
                <div className="copy-link-container">
                    <input
                        type="text"
                        id="copy-link-input"
                        value={`${window.location.origin}/orders/${orderId}`}
                        readOnly
                    />
                    <button
                        type="button"
                        id="copy-link-button"
                        onClick={() => {
                            navigator.clipboard.writeText(`${window.location.origin}/orders/${orderId}`);
                        }}
                    >
                        {link.button}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default NewOrder;
