import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import NewOrder from "./views/newOrderView";
import Home from "./views/homeView";

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/orders/:orderId" element={<Home />} />
                    <Route path="/orders/:orderId/success" element={<NewOrder />} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
