import { instructionsPage, setKeshevCookie } from "../services/keshevPrevSys";

function useUpdateOrder({ orderId, dob, fullName, gender, email }) {
    const updateOrder = async () => {
        const response = await fetch(
            `${process.env.REACT_APP_KESHEV_B2C_API_URL}/keshev-b2c-api/orders/${orderId}/generateTest`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    client_id: process.env.REACT_APP_KESHEV_B2C_API_CLIENT_ID,
                },
                body: JSON.stringify({
                    fullName,
                    gender,
                    dob,
                    email,
                }),
                withCredentials: true,
            }
        );
        if (!response.ok) {
            return;
        }
        const keshevCookie = await response.json();
        setKeshevCookie(keshevCookie.message);
        instructionsPage();
    };

    return { updateOrder };
}

export default useUpdateOrder;
