import React from "react";
import PropTypes from "prop-types";
import PatientProfileForm from "./patientProfileForm";

function Checkout({ orderId }) {
    return <PatientProfileForm orderId={orderId} />;
}

Checkout.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default Checkout;
