import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Form, Button, Alert, Col, Row } from "react-bootstrap";
import "bootstrap-icons/font/bootstrap-icons.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useStores } from "../stores/RootStore";
import { isSelectedDobDateValid } from "../services/validation";
import useOrderInfo from "../hooks/useOrderInfo";
import contentData from "../services/content";
import logo from "../assest/logo.png";
import useUpdateOrder from "../hooks/useUpdateOrder";

function PatientProfileForm({ orderId }) {
    const { PatientStore } = useStores();
    const { updatePatientData } = PatientStore;
    const [email, setEmail] = useState("");
    const [fullName, setFullName] = useState("");
    const [gender, setGender] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isDobValid, setIsDobValid] = useState(false);
    const [validated, setValidated] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [isChecked, setChecked] = useState(false);

    const { orderInfo, loading, error } = useOrderInfo(orderId);
    const { updateOrder } = useUpdateOrder({ orderId, dob: selectedDate, fullName, gender, email });

    const { importantNotes } = contentData.homePage;

    const {
        patientName,
        birthDate,
        ErrorWhileRetrievingPaymentInformation,
        subtitle,
        sex,
        proceedToTest,
        paymentInfoLoading,
        acceptTerms,
        invalidAge,
    } = contentData.homePage.form;

    useEffect(() => {
        if (!loading && !error) {
            if (orderInfo.data?.status === "COMPLETED") {
                if (orderInfo.data?.email) setEmail(orderInfo.data.email);
                setShowForm(true);
            } else if (orderInfo.data?.status === "REDEEMED") {
                window.location.href = `${process.env.REACT_APP_KESHEV_B2C_API_URL}/keshev-b2c-api/checkTestStatus/${orderInfo.data.sid}`;
            } else {
                window.location.href = process.env.REACT_APP_WIX_URL;
            }
        }
    }, [loading, error, orderInfo]);

    const validateForm = () => {
        const validDob = isSelectedDobDateValid(selectedDate);
        setIsDobValid(validDob);

        return validDob && fullName && gender && isChecked;
    };

    const setPatientStore = () => {
        updatePatientData({ key: "fullName", value: fullName });
        updatePatientData({ key: "dob", value: selectedDate });
        updatePatientData({ key: "gender", value: gender });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setValidated(true);

        const isFormValid = validateForm();
        if (isFormValid) {
            setPatientStore();
            updateOrder();
        }
    };

    return (
        <div className="container rtl-form">
            <img src={logo} style={{ width: "210px" }} alt="" />
            <div className="row">
                <div className="col-6">
                    <h2 style={{ marginTop: "40px" }}>{subtitle}</h2>
                    {loading && <p className="mb-4">{paymentInfoLoading}...</p>}
                    {error && (
                        <p className="mb-4">
                            {ErrorWhileRetrievingPaymentInformation}: {error}
                        </p>
                    )}
                    {showForm && (
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="10" controlId="fullName">
                                    <Row>
                                        <Form.Label column md="3">
                                            {patientName.label}
                                        </Form.Label>
                                        <Col>
                                            <Form.Control
                                                style={{ maxWidth: "200px" }}
                                                type="fullName"
                                                value={fullName}
                                                onChange={(e) => setFullName(e.target.value)}
                                                required
                                                isInvalid={validated && !fullName}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="10" controlId="gender">
                                    <Row>
                                        <Form.Label column md="3">
                                            {sex.label}
                                        </Form.Label>
                                        <Col>
                                            <Form.Select
                                                value={gender}
                                                onChange={(e) => setGender(e.target.value)}
                                                required
                                                isInvalid={validated && !gender}
                                                style={{ maxWidth: "200px" }}
                                            >
                                                <option value="" />
                                                <option value="M">{sex.male}</option>
                                                <option value="F">{sex.female}</option>
                                            </Form.Select>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="10" controlId="dob">
                                    <Form.Label column md="3">
                                        {birthDate.label}
                                    </Form.Label>
                                    <DatePicker
                                        className="mx-2"
                                        selected={selectedDate}
                                        onChange={(date) => setSelectedDate(date)}
                                        dateFormat="MM/dd/yyyy"
                                        maxDate={new Date()}
                                        showYearDropdown
                                        scrollableYearDropdown
                                        yearDropdownItemNumber={100}
                                        customInput={
                                            <Form.Control
                                                isInvalid={validated && !isDobValid}
                                                style={{
                                                    borderColor: validated && !isDobValid ? "#dc3545" : "",
                                                    maxWidth: "200px",
                                                }}
                                            />
                                        }
                                    />
                                    {!isDobValid && validated && (
                                        <Alert variant="danger" className="mt-2 mb-1">
                                            {invalidAge}
                                        </Alert>
                                    )}
                                </Form.Group>
                            </Row>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="10" controlId="gender">
                                    <Row>
                                        <Form.Check
                                            className="col-lg-1 col-md-2"
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={() => setChecked(!isChecked)}
                                            required
                                            isInvalid={validated && !isChecked}
                                            id="terms"
                                        />
                                        <Form.Label className="col py-0 px-0" htmlFor="terms">
                                            <span>{acceptTerms.label} </span>
                                            <a
                                                href={`${process.env.REACT_APP_WIX_URL}/terms`}
                                                target="_blank"
                                                rel="noreferrer noopener"
                                            >
                                                {acceptTerms.link}
                                            </a>
                                        </Form.Label>
                                    </Row>
                                </Form.Group>
                            </Row>

                            <Button variant="primary" type="submit" className="btn-special mb-3">
                                {proceedToTest}
                            </Button>
                        </Form>
                    )}
                </div>
                <div className="col-6">
                    <div style={{ marginTop: "80px" }}>
                        <ul className="custom-list">
                            <li className="quiet">{importantNotes.one}</li>
                            <li className="time">{importantNotes.two}</li>
                            <li className="email">{importantNotes.three}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

PatientProfileForm.propTypes = {
    orderId: PropTypes.string.isRequired,
};

export default PatientProfileForm;
