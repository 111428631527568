import { makeAutoObservable } from "mobx";

class PatientStore {
    fullName = "";

    email = "";

    gender = "";

    dob = "";

    constructor() {
        makeAutoObservable(this);
    }

    updatePatientData = (obj) => {
        this[obj.key] = obj.value;
    };
}

export default PatientStore;
