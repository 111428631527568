const contentData = {
    homePage: {
        title: "التسجيل",
        subtitle: "يرجى ملء النموذج للمتابعة",
        tetsDescription: "وصف الإختبار",
        useWebBrowserInsteadOfMobile: "يرجى استخدام متصفح الويب بدلاً من الجهاز المحمول.",
        importantNotes: {
            one: "أنا في بيئة هادئة.",
            two: "أعطي نفسي 25-30 دقيقة لإجراء الإختبار.",
            three: "بعد إنهاء الاختبار، سيتم إرسال رابط للتقرير إلى البريد الإلكتروني الذي تم تزويده أثناء عملية الدفع.",
        },
        form: {
            subtitle: "معلومات شخصية للممتحَن",
            patientName: {
                label: "اسم المنتفع",
            },
            submit: "إحفظ",
            sex: {
                label: "الجنس",
                male: "ذكر",
                female: "أنثى",
            },
            birthDate: {
                label: "تاريخ الميلاد",
            },
            acceptTerms: {
                label: "أوافق على",
                link: "الشروط والأحكام",
            },
            newsletter: "الاشتراك في ال (Newsletter)",
            invalidAge: "يرجى تقديم تاريخ ميلاد صالح (يجب أن يكون العمر 6 سنوات أو أكثر)",
            proceedToTest: "ابدأ الاختبار",
            paymentInfoLoading: "تحميل تكلفة الفحص",
            ErrorWhileRetrievingPaymentInformation: "حدث خطأ أثناء استرداد تكلفة الفحص",
        },
    },
    newOrder: {
        title: 'تم شراء إختبار "تركيز" بنجاح',
        subtitle: " رمز تأكيد الشراء هو",
        content:
            "سنرسل لك الآن بريدًا إلكترونيًا يحتوي على رابط لإجراء الإختبار٫ يمكنك البداء بالإختبار الان او لاحقا.",
        proceedToTest: "ابدأ الإختبار الان",
        proceedLater: "سأجري الإختبار لاحقا",
        footer: {
            highlighted: "لم تحصل على الرابط؟",
            text: " تحقق من صندوق البريد العشوائي (Spam) أو",
            cta: "انقر لإعادة الإرسال",
            text2: "او استخدم الرابط التالي لاجراء الإختبار:",
        },
        link: {
            button: "إنسخ الرابط",
        },
    },
};

export default contentData;
