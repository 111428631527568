import { useState, useEffect } from "react";

const useOrderInfo = (orderId) => {
    const [orderInfo, setOrderInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchOrderInfo = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_KESHEV_B2C_API_URL}/keshev-b2c-api/orders/${orderId}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            client_id: process.env.REACT_APP_KESHEV_B2C_API_CLIENT_ID,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error("Failed to fetch order information");
                }

                const data = await response.json();
                setOrderInfo(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchOrderInfo();
    }, []);

    return { orderInfo, loading, error };
};

export default useOrderInfo;
